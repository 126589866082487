import { useField, useFormikContext } from 'formik'
import { map, findKey } from 'lodash-es'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { FormikValues } from 'formik/dist/types'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { useUi } from '../../use-ui'
import { type SelectPropsCommon } from './index'
import * as UI from '@/ui'

type ValueOf<T> = T[keyof T]

export type FormikSelectProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & SelectPropsCommon &
  Omit<StateManagerProps, 'type' | 'options' | 'name' | 'value' | 'isMulti' | 'components' | 'styles' | 'defaultValue'>

export const FormikSelect = ({
  name,
  options,
  errorMessage,
  placeholder,
  depends,
  setIsHidden,
  onChange,
  multiselect,
  ...props
}: FormikSelectProps) => {
  type Option = { label: string; value: ValueOf<FormikValues> }

  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched, setValue }] = useField({ name, ...props })
  const [selectedValue, setSelectedValue] = useState<Option | Option[]>()

  options = Array.isArray(options) ? Object.assign({}, ...options.map((v) => ({ [v]: v }))) : options

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }

    let currentValues = (values as FormikValues)[name]

    if (!Array.isArray(currentValues)) {
      currentValues = [currentValues]
    }

    currentValues = currentValues.map((currentValue: Option['value']) => {
      const key = findKey(options, (value) => value === currentValue)

      return key ? { label: key, value: currentValue } : undefined
    })

    if (multiselect) {
      setSelectedValue(currentValues)
    } else {
      setSelectedValue(currentValues[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  const { className, attributes } = useUi({
    name: 'Form.Select',
    className: `${
      errorMessage
        ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
        : meta.touched &&
          'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300'
    } block w-full focus:outline-none sm:text-sm rounded-md border`,
  })

  return (
    <div {...attributes} className="relative rounded-md shadow-sm">
      <Select<Option, boolean>
        name={name}
        className={className}
        placeholder={placeholder}
        options={map(options, (value, label) => ({ value, label }))}
        onChange={(newValue, actionMeta): void => {
          if (!Array.isArray(newValue)) {
            newValue = [newValue as Option]
          }
          const value = newValue.map((option) => option.value)

          setValue(multiselect ? value : value[0])
          onChange && onChange(value, actionMeta)
        }}
        value={selectedValue}
        isMulti={multiselect}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#d0d9ea',
            primary: '#4e8ac5',
          },
        })}
        {...props}
      />

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </div>
  )
}

FormikSelect.displayName = 'Form.Select.FormikSelect'

export default FormikSelect
