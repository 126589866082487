import { Field, useField, useFormikContext } from 'formik'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type TextareaPropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type FormikTextareaProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & TextareaPropsCommon

export const FormikTextarea = ({
  name,
  onKeyUp,
  errorMessage,
  boxSize,
  placeholder,
  depends,
  setIsHidden,
  className,
  ...props
}: FormikTextareaProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name, ...props })

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const ui = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Textarea',
    className: `${
      errorMessage
        ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
        : meta.touched &&
          'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300'
    } ${className} focus:ring-selphBlue-300 focus:border-selphBlue-300`,
  })

  const keyup = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyUp && onKeyUp(e)
  }

  return (
    <>
      <div className="relative rounded-md shadow-sm">
        <Field
          name={name}
          component="textarea"
          onKeyUp={keyup}
          placeholder={placeholder}
          {...ui.attributes}
          {...props}
          className={ui.className}
        />
        {errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}

        {meta.touched && !errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          </div>
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikTextarea.displayName = 'Form.Textarea.FormikTextarea'

export default FormikTextarea
