import { DateTime } from 'luxon-business-days'
import { useState } from 'react'
import { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateStamp } from '../../../../helpers/date'
import { type Breakpoints } from '../../use-ui'
import { HelpText } from '../help-text'
import { Label } from '../label'
import { FormikDate, type FormikDateProps } from './formik-date'
import { InputDate, type InputDateProps } from './input-date'
import * as UI from '@/ui'

export type DateSize = 'medium'

export type DateProps = DatePropsFormik | DatePropsInput

export type DatePropsCommon = {
  name: string
  format?: 'date' | 'datetime'
  placeholder?: string
  errorMessage?: string
  value?: string | Date | DateStamp

  maxDate?: ReactDatePickerProps['maxDate'] | DateTime | string
  minDate?: ReactDatePickerProps['minDate'] | DateTime | string
  onChange?: ReactDatePickerProps['onChange']
  onSelect?: ReactDatePickerProps['onSelect']

  boxSize?: Breakpoints<DateSize>
  readOnly?: 'readOnly'
} & Omit<
  ReactDatePickerProps,
  'maxDate' | 'minDate' | 'value' | 'name' | 'type' | 'placeholderText' | 'onChange' | 'onSelect' | 'readOnly'
>

export type DatePropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type DatePropsFormik = FormikDateProps &
  DatePropsShared & {
    type?: 'formik'
  }

export type DatePropsInput = InputDateProps &
  DatePropsShared & {
    type?: 'input'
  }

export const boxSizes = {
  default: 'block w-full pr-10   focus:outline-none sm:text-sm rounded-md',

  medium: '',
}

export const Date = ({ name, type = 'formik', helpTextAbove, helpTextBelow, children, ...props }: DateProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText className="-mb-1">{helpTextAbove}</HelpText>}

        {type === 'formik' ? (
          <FormikDate name={name} setIsHidden={setIsHidden} {...(props as Omit<DatePropsFormik, 'name'>)} />
        ) : (
          <InputDate name={name} {...(props as Omit<DatePropsInput, 'name'>)} />
        )}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

Date.displayName = 'Form.Date'

export default Date
