import { map } from 'lodash-es'
import Select from 'react-select'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import { useUi } from '../../use-ui'
import { type SelectPropsCommon } from './index'
import * as UI from '@/ui'

export type InputSelectProps = SelectPropsCommon &
  Omit<StateManagerProps, 'type' | 'options' | 'name' | 'isMulti' | 'components' | 'styles'>

export const InputSelect = ({ name, options, placeholder, errorMessage, multiselect, ...props }: InputSelectProps) => {
  const { className, attributes } = useUi({
    name: 'Form.Select',
  })

  return (
    <div {...attributes} className="mt-1 relative rounded-md shadow-sm">
      <Select
        name={name}
        className={className}
        placeholder={placeholder}
        options={map(options, (value, label) => ({ value, label }))}
        isMulti={multiselect}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#d0d9ea',
            primary: '#4e8ac5',
          },
        })}
        {...props}
      />

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </div>
  )
}

InputSelect.displayName = 'Form.Select.InputSelect'

export default InputSelect
