import { createRef, useState } from 'react'
import { useUi } from './use-ui'

export type TooltipColors = 'grey' | 'primary' | 'secondary' | 'info' | 'danger' | 'success' | 'warning' | 'light'
export type TooltipPositions = 'left' | 'right'
export type TooltipSizes = 'small' | 'medium' | 'large' | 'xl'
export type TooltipWidths = 'full' | 'half' | 'small' | 'medium' | 'large' | 'fit'

export type TooltipProps = {
  message: React.ReactNode
  children: React.ReactNode
  color?: TooltipColors
  position?: TooltipPositions
  size?: TooltipSizes
  width?: TooltipWidths
  className?: string
}
export const Tooltip = ({
  message,
  color = 'grey',
  position = 'right',
  size = 'medium',
  width = 'fit',
  className,
  children,
}: TooltipProps) => {
  const tipRef = createRef<HTMLDivElement>()
  const [isHover, setIsHover] = useState<boolean>()

  const alt = {
    left: 'right',
    right: 'left',
  }

  const widths = {
    full: 'w-full',
    half: 'w-1/2',
    small: 'w-48',
    medium: 'w-72',
    large: 'w-96',
    fit: 'w-fit min-w-72',
  }

  const sizes = {
    default: 'font-bold',
    small: 'px-3 py-2 text-xs',
    medium: 'px-3 py-2 text-sm',
    large: 'px-4 py-2',
    xl: 'px-4 py-2 text-xl',
  }

  const positions = {
    default: `${isHover ? `block m${alt[position][0]}-3` : `hidden m${alt[position][0]}-3`} ${
      alt[position]
    }-full absolute rounded flex items-center transition-all duration-150`, // Tailwind: mr-3 ml-3 mr-2 ml-2 right-full left-full -right-1 -left-1
    left: 'bg-gradient-to-l',
    right: 'bg-gradient-to-r',
  }

  const colors = {
    grey: 'bg-gray-700 text-white',
    primary: 'bg-blue-500 text-white',
    secondary: 'bg-gray-500 text-white',
    info: 'bg-cyan-500 text-white',
    danger: 'bg-red-500 text-white',
    success: 'bg-green-500 text-white',
    warning: 'bg-yellow-500 text-white',
    light: 'bg-white text-black',
  }

  const tooltipStyle = useUi({
    styles: {
      positions: { options: positions, selected: position },
      sizes: { options: sizes, selected: size },
      colors: { options: colors, selected: color },
      widths: { options: widths, selected: width },
    },
    name: 'Tooltip',
    className: `${className} bottom-1/2 translate-y-1/2 `,
  })

  const symbolStyle = useUi({
    styles: {
      colors: { options: colors, selected: color },
    },
    name: 'Tooltip',
    className: `h-3 w-3 absolute -${alt[position]}-1 rotate-45`,
  })

  if (!message) {
    return <>{children}</>
  }

  return (
    <div className={`${width === 'full' ? 'block' : 'inline-block'}`}>
      <div
        {...tooltipStyle.attributes}
        className={`relative ${width === 'full' ? 'w-full' : 'w-fit'}`}
        onPointerEnter={() => setIsHover(true)}
        onPointerLeave={() => setIsHover(false)}
      >
        <div className={tooltipStyle.className} ref={tipRef}>
          <div className={symbolStyle.className} />
          {message}
        </div>
        {children}
      </div>
    </div>
  )
}

Tooltip.displayName = 'Tooltip'

export default Tooltip
