import { useState } from 'react'
import { type Breakpoints, useUi } from '../../use-ui'

import { Label } from '../label'
import { HelpText } from '../help-text'
import { InputTextarea, type InputTextareaProps } from './input-textarea'
import { FormikTextarea, type FormikTextareaProps } from './formik-textarea'
import * as UI from '@/ui'

export type TextareaSize = 'medium'

export type TextareaProps = TextareaPropsFormik | TextareaPropsInput

export type TextareaPropsCommon = {
  name: string
  placeholder?: string
  errorMessage?: string
  theme?: 'light' | 'dark'
  height?: 'small' | 'medium' | 'large'
  className?: string
  rows?: number

  boxSize?: Breakpoints<TextareaSize>
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'type'>

export type TextareaPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type TextareaPropsFormik = FormikTextareaProps &
  TextareaPropsShared & {
    type?: 'formik'
  }

export type TextareaPropsInput = InputTextareaProps &
  TextareaPropsShared & {
    type?: 'input'
  }

export const boxSizes = {
  default: `block w-full pr-10 focus:outline-none sm:text-sm rounded-md`,
  medium: '',
}

export const Textarea = ({
  name,
  type = 'formik',
  helpTextAbove,
  helpTextBelow,
  height = 'medium',
  className,
  children,
  ...props
}: TextareaProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  const heights = {
    none: '',
    small: 'min-h-[4rem]',
    medium: 'min-h-[8rem]',
    large: 'min-h-[12rem]',
  }

  const ui = useUi({
    styles: {
      heights: { options: heights, selected: height },
    },
    name: 'Form.Textarea',
    className: className,
  })

  return (
    <div {...ui.attributes} className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText className="-mb-1">{helpTextAbove}</HelpText>}

        {type === 'formik' && (
          <FormikTextarea name={name} setIsHidden={setIsHidden} className={ui.className} {...props} />
        )}
        {type === 'input' && <InputTextarea name={name} className={ui.className} {...props} />}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

Textarea.displayName = 'Form.Textarea'

export default Textarea
