import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useUi } from '../../use-ui'

import { DateStamp, fromStringDate } from '../../../../helpers/date'
import { type DatePropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type InputDateValue = string | Date | DateStamp

export type InputDateProps = {
  initialValue?: InputDateValue
  value?: InputDateValue
} & Omit<DatePropsCommon, 'initialValue' | 'value'>

export const InputDate = ({
  name,
  boxSize,
  errorMessage,
  placeholder,
  initialValue,
  minDate,
  maxDate,
  // onKeyDown,
  onChange,
  format = 'date',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  readOnly,
  ...props
}: InputDateProps) => {
  const [value, setValue] = useState<Date | undefined>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setValue(parseDate(initialValue))
  })

  const { className, attributes } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Date',
    className: `${
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    } focus:ring-selphBlue-300 focus:border-selphBlue-300`,
  })

  const parseDate = (date: string | Date | DateStamp | undefined | null): Date | undefined =>
    date
      ? date instanceof Date
        ? date
        : date instanceof DateStamp && typeof date != 'string'
        ? date.toJSDate()
        : typeof date === 'string' && date.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)
        ? fromStringDate(date).toJSDate()
        : undefined
      : undefined

  return (
    <>
      <DatePicker
        name={name}
        placeholderText={placeholder}
        {...(format === 'datetime' ? { showTimeSelect: true } : {})}
        {...attributes}
        className={className}
        {...(props as Omit<InputDateProps, 'value' | 'name' | 'readOnly'>)}
        selected={value}
        // onKeyDown={(event) => {
        //   setValue(parseValue(event.currentTarget.value))
        //   onKeyDown && onKeyDown(event.currentTarget.value)
        // }}
        onChange={(val, extra) => {
          setValue(parseDate(val as InputDateValue))
          onChange && onChange(val, extra)
        }}
        dateFormat={
          {
            date: 'dd/MM/yyyy',
            datetime: 'dd/MM/yyyy HH:ii',
          }[format]
        }
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        minDate={parseDate(minDate)}
        maxDate={parseDate(maxDate)}
        calendarStartDay={1}
      />
      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

InputDate.displayName = 'Form.Date.InputDate'

export default InputDate
