import { useField, useFormikContext, getIn } from 'formik'
import { map } from 'lodash-es'
import { Field } from 'formik'
import { useEffect, useState } from 'react'
import { FormikValues } from 'formik/dist/types'
import { dependsMatch, dependsParseValue, ValidateDepends, ValidateDependsValue } from '../validation'
import { useUi } from '../../use-ui'
import { type CheckboxPropsCommon } from './index'
import * as UI from '@/ui'

export type FormikCheckboxGroupProps = {
  optionStyle?: 'button' | 'checkbox'
  parentLabel?: string
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & CheckboxPropsCommon

export const dotProps = (selected: boolean) => ({
  className:
    `${selected ? 'bg-white' : 'bg-gray-200'} h-[18px] w-[19px]  rounded-sm relative ` +
    `${selected ? 'before:opacity-100 before:scale-100 ' : 'before:opacity-0 before:scale-150'} before:bg-cyan-500 ` +
    `${
      selected ? 'after:opacity-100' : 'after:opacity-0'
    } after:scale-125 after:absolute after:content-[""] after:left-[3.5px] after:top-[8.5px] after:bg-cyan-500 after:w-[2px] after:h-[2px] after:shadow-tick after:rotate-45`,
})

export const labelProps = (selected: boolean, errorMessage: string | undefined) => ({
  className: `${
    selected ? 'bg-cyan-500 border-cyan-500' : `${errorMessage ? 'border-red-400' : 'border-gray-400'} bg-gray-50 `
  }  flex w-fit items-center justify-evenly m-0 mr-3 mb-3 rounded-md cursor-pointer py-2 px-3 border`,
})

export const spanProps = (selected: boolean) => ({
  className: `${selected ? 'text-white' : 'text-gray-500'} pl-2 text-sm`,
})

export const FormikCheckboxGroup = ({
  name,
  options,
  errorMessage,
  gridGap,
  gridSize,
  gridAlign,
  gridColVerticalAlign,
  depends,
  setIsHidden,
  parentLabel,
  optionStyle = 'checkbox',
  ...props
}: FormikCheckboxGroupProps) => {
  const { isSubmitting, values, setFieldValue } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name, ...props })
  const [allOptionsChecked, setAllOptionsChecked] = useState<boolean>(false)
  const optionsLength = Object.keys(options).length

  useEffect(() => {
    setAllOptionsChecked((getIn(values, name) || []).length === optionsLength)
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className, attributes } = useUi({
    name: 'Form.CheckboxGroup',
    className: `focus:ring-indigo-500 h-4 w-4  ${
      errorMessage
        ? 'ring-red-500 text-red-600 border-red-500'
        : meta.touched
        ? 'ring-green-500 text-green-600 border-green-300'
        : 'text-indigo-600 border-gray-300'
    }`,
  })
  return (
    <div {...attributes}>
      <UI.Block gap="xs">
        {
          {
            checkbox: (
              <UI.Grid gap={gridGap} size={gridSize} align={gridAlign} colVerticalAlign={gridColVerticalAlign}>
                {parentLabel && (
                  <UI.Form.Label type="option" text={parentLabel}>
                    <Field
                      type="checkbox"
                      name={name}
                      checked={allOptionsChecked}
                      className={className}
                      {...props}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const optionsList = Object.keys(options)
                        e.currentTarget.checked ? setFieldValue(name, optionsList) : setFieldValue(name, [])
                      }}
                    />
                  </UI.Form.Label>
                )}
                {map(options, (value, label) => (
                  <div key={value + label} className={`${gridSize === 'flex' ? 'mr-5' : ''} ${parentLabel && 'ml-10'}`}>
                    <UI.Form.Label type="option" text={label}>
                      <Field type="checkbox" name={name} value={`${value}`} className={className} {...props} />
                    </UI.Form.Label>
                  </div>
                ))}
              </UI.Grid>
            ),
            button: (
              <div className="-mb-3 -mr-3">
                {map(options, (value, label) => {
                  const selected = (values as FormikValues)[name]?.includes(dependsParseValue(value))
                  return (
                    <div className="inline-block" key={value + label}>
                      <label {...labelProps(selected, errorMessage)}>
                        <Field type="checkbox" name={name} value={`${value}`} className="hidden" />
                        <div {...dotProps(selected)}></div>
                        <span {...spanProps(selected)}>{label}</span>
                      </label>
                    </div>
                  )
                })}
              </div>
            ),
          }[optionStyle]
        }
        {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
      </UI.Block>
    </div>
  )
}

FormikCheckboxGroup.displayName = 'Form.CheckboxGroup.FormikCheckboxGroup'

export default FormikCheckboxGroup
