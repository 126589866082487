import { Field, useField, useFormikContext } from 'formik'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { Fragment, useEffect } from 'react'
import cx from 'classnames'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type TextPropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type FormikTextProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & TextPropsCommon

export const FormikText = ({
  name,
  field = 'text',
  onKeyUp,
  errorMessage,
  boxSize,
  placeholder,
  depends,
  setIsHidden,
  ...props
}: FormikTextProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name })

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className, attributes } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Text',
    className: cx(
      props?.readOnly && 'cursor-not-allowed bg-selphGrey-100',
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300',
      meta.touched &&
        'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300',
      `${props.className} focus:ring-selphBlue-300 focus:border-selphBlue-300`,
    ),
  })

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  return (
    <Fragment>
      <div className={`relative rounded-md shadow-sm ${className}`}>
        <Field
          name={name}
          type={field}
          onKeyUp={keyup}
          placeholder={placeholder}
          {...attributes}
          {...props}
          className={className}
        />

        {errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}

        {meta.touched && !errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          </div>
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </Fragment>
  )
}

FormikText.displayName = 'Form.Text.FormikText'

export default FormikText
