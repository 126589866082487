import { useUi } from '../../use-ui'
import { type CheckboxPropsCommon } from './index'
import * as UI from '@/ui'

export type InputCheckboxProps = {
  label?: string
} & CheckboxPropsCommon

export const InputCheckbox = ({ name, label, errorMessage, ...props }: InputCheckboxProps) => {
  const { className, attributes } = useUi({
    name: 'Form.Checkbox',
    className: `focus:ring-indigo-500 h-4 w-4  ${errorMessage && 'ring-red-500 text-red-600 border-red-500'}`,
  })

  return (
    <div {...attributes}>
      {label ? (
        <UI.Form.Label type="option" text={label}>
          <input type="checkbox" name={name} className={className} {...props} />
        </UI.Form.Label>
      ) : (
        <input type="checkbox" name={name} className={className} {...props} />
      )}

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </div>
  )
}

InputCheckbox.displayName = 'Form.Checkbox.InputCheckbox'

export default InputCheckbox
