import { useUi } from '../../use-ui'

import { type TextareaPropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type InputTextareaProps = {
  initialValue?: string | number | undefined
} & TextareaPropsCommon

export const InputTextarea = ({
  name,
  boxSize,
  errorMessage,
  placeholder,
  initialValue,
  ...props
}: InputTextareaProps) => {
  const { className, attributes } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Textarea',
    className: `${
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    } focus:ring-selphBlue-300 focus:border-selphBlue-300`,
  })

  return (
    <>
      <textarea
        name={name}
        defaultValue={initialValue}
        placeholder={placeholder}
        {...attributes}
        className={className}
        {...props}
      />
      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

InputTextarea.displayName = 'Form.Textarea.InputTextarea'

export default InputTextarea
