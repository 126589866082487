import { useUi, type Breakpoints } from './use-ui'

export type ParagraphSizes = 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl' | '3xl' | 'none'
export type ParagraphColors =
  | 'darkBlue'
  | 'lightBlue'
  | 'midBlue'
  | 'darkGrey'
  | 'lightGrey'
  | 'hotPink'
  | 'offWhite'
  | 'white'
export type ParagraphWeights = 'thin' | 'semiBold' | 'regular'

export type ParagraphProps = {
  children: React.ReactNode
  className?: string
  size?: Breakpoints<ParagraphSizes>
  weight?: ParagraphWeights
  color?: ParagraphColors
}

export const Paragraph = ({
  className,
  weight = 'regular',
  size = 'none',
  color = 'darkBlue',
  children,
}: ParagraphProps) => {
  const sizes = {
    none: '',
    xs: 'text-xs',
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
    xl: 'text-xl',
    xxl: 'text-3xl',
    '3xl': 'text-4xl',
  }

  const colors = {
    darkBlue: 'text-selphBlue-600',
    midBlue: 'text-selphBlue-500',
    lightBlue: 'text-selphBlue-400',
    darkGrey: 'text-selphGrey-800',
    lightGrey: 'text-selphGrey-600',
    hotPink: 'text-hotPink-500',
    offWhite: 'text-selphGrey-50',
    white: 'text-white',
  }

  const weights = {
    thin: 'font-thin',
    regular: 'font-normal',
    semiBold: 'font-semibold',
  }

  const ui = useUi({
    styles: {
      size: { options: sizes, selected: size },
      color: { options: colors, selected: color },
      weight: { options: weights, selected: weight },
    },
    name: 'Paragraph',
    className,
  })

  return (
    <p {...ui.attributes} className={ui.className}>
      {children}
    </p>
  )
}

Paragraph.displayName = 'Paragraph'

export default Paragraph
